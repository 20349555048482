export const ClientFormUserRole = {
  Approver: 30,
  Contributor: 20,
  Owner: 50,
  Validator: 40,
  Viewer: 10,
} as const;

export type ClientFormUserRoleNames = 'approver' | 'contributor' | 'owner' | 'validator' | 'viewer';

export const ClientFormUserRoleKeys = {
  [ClientFormUserRole.Approver]: 'common:form-role.approver',
  [ClientFormUserRole.Contributor]: 'common:form-role.contributor',
  [ClientFormUserRole.Owner]: 'common:form-role.owner',
  [ClientFormUserRole.Validator]: 'common:form-role.validator',
  [ClientFormUserRole.Viewer]: 'common:form-role.viewer',
} as const;
